import React from "react";

// Style
import styled from "styled-components";

// Component
import GameOfLife from "./index";

const Container = styled.div`
  margin-bottom: 16px;
`;

const GameOfLifeContainer = () => {
  if (typeof window !== "undefined") {
    return (
      <Container>
        <GameOfLife />
      </Container>
    );
  }

  return <div />;
};

export default GameOfLifeContainer;
