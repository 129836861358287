import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import {
  primary,
  neuzeitGroLight,
  mediaQueries,
  fontSize,
} from "../../styles/fonts";

const Container = styled.div`
  height: 32px;
  width: calc(100vw - 64px);
  display: flex;
  padding: 16px 32px 0px 32px;
  justify-content: space-between;
  position: fixed;

  @media (min-width: ${mediaQueries.tablet}) {
    display: none;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  ${neuzeitGroLight}
  font-size: ${fontSize.md};

  @media (max-width: ${mediaQueries.mobile}) {
    font-size: ${fontSize.sm};
  }

  &:hover {
    color: ${primary};
  }
`;

function MobileNav() {
  return (
    <Container>
      <StyledLink to="/" activeStyle={{ color: primary }}>
        HOME
      </StyledLink>
      <StyledLink to="/allposts/" activeStyle={{ color: primary }}>
        ALL POSTS
      </StyledLink>
      <StyledLink to="/about/" activeStyle={{ color: primary }}>
        ABOUT
      </StyledLink>
    </Container>
  );
}

export default MobileNav;
