import React from 'react';
import { fontSize, neuzeitGroLight } from "../../styles/fonts";
import styled from 'styled-components';

const TextContainer = styled.div`
  ${neuzeitGroLight};  
  font-size: ${fontSize.sm};
`

export default function Text({ children }) {
 return <TextContainer>{children}</TextContainer>
}

