export const neuzeitGroLight = `
  font-family: NeuzeitGro-Lig;
  font-weight: normal;
  font-style: normal;
  `;

export const primary = "#ff5733";

export const mediaQueries = {
  tablet: `800px`,
  mobile: `500px`
};

export const fontSize = {
  xl: "60px",
  lg: "40px",
  md: "28px",
  sm: "20px",
  xs: "18px",
  xxs: "14px"
};
