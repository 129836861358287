import React from "react";

// Style
import styled from "styled-components";
import { neuzeitGroLight, fontSize } from "../../styles/fonts";

const StyledP = styled.p`
${neuzeitGroLight}
font-size: ${fontSize.xxs}
`;

const EndNote = props => <StyledP {...props}>{props.children}</StyledP>;

export default EndNote;
