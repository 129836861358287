export default function getWidth() {
  // ssr guard
  if (typeof window === "undefined") return;
  const navWidth = 162;
  const margin = 64;
  const maxCanvasWidth = 800;
  const maxWidth = maxCanvasWidth + margin + navWidth;

  const removeNavWidth = window.innerWidth > 800 ? 162 : 0;

  if (window.innerWidth > maxWidth) return maxCanvasWidth;

  if (window.innerWidth < maxWidth)
    return window.innerWidth - 66 - removeNavWidth;
}
