import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import {
  primary,
  neuzeitGroLight,
  mediaQueries,
  fontSize
} from "../../styles/fonts";

const NavContainer = styled.div`
  margin-left: 32px;
  margin-right: 32px;
  position: fixed;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 130px;

  @media (max-width: ${mediaQueries.tablet}) {
    display: none;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  ${neuzeitGroLight}
  font-size: ${fontSize.md};

  &:hover {
    color: ${primary};
  }
`;

const LeftNav = () => {
  return (
    <NavContainer>
      <LinkContainer>
        <StyledLink to="/" activeStyle={{ color: primary }}>
          HOME
        </StyledLink>
        <StyledLink to="/allposts/" activeStyle={{ color: primary }}>
          ALL POSTS
        </StyledLink>
        <StyledLink to="/about/" activeStyle={{ color: primary }}>
          ABOUT
        </StyledLink>
      </LinkContainer>
    </NavContainer>
  );
};

export default LeftNav;
