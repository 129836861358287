import React from "react";

import Visualizer from ".";

const VisualizerContainer = () => {
  if (typeof window !== "undefined") {
    return <Visualizer />;
  }

  return <div />;
};

export default VisualizerContainer;
