import React from "react";

// Components
import LeftNav from "./LeftNav";
import MobileNav from "./MobileNav";
import InnerLayout from "./InnerLayout";

// Style
import "../../styles/global.css";
import styled from "styled-components";
import { mediaQueries } from "../../styles/fonts";

const HomeContainer = styled.div`
  z-index: 0;
  padding-top: 32px;
  overflow: scroll;
  height: calc(100vh - 32px);

  @media (max-width: ${mediaQueries.tablet}) {
    padding-top: 64px;
    height: calc(100vh - 64px);
  }
`;

function Layout({ children }) {
  return (
    <>
      <MobileNav />
      <HomeContainer>
        <LeftNav />
        <InnerLayout>{children}</InnerLayout>
      </HomeContainer>
    </>
  );
}

export default Layout;
