import React from "react";

// Style
import styled from "styled-components";
import { primary, neuzeitGroLight } from "../../styles/fonts";

const A = styled.a`
  ${neuzeitGroLight}
  color: black;

  &:hover {
    color: ${primary};
  }
`;

const StyledAnchor = props => (
  <A rel="noopener" target="_blank" {...props}>
    {props.children}
  </A>
);

export default StyledAnchor;
