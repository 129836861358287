import React from "react";

// Style
import styled from "styled-components";
import { mediaQueries } from "../../styles/fonts";

const TemplateContainer = styled.div`
  margin-left: 162px;
  display: flex;

  @media (max-width: ${mediaQueries.tablet}) {
    margin-left: 0px;
  }
`;

const PageContent = styled.div`
  width: 800px;
  margin: 0px auto;
  padding: 0px 32px;
  min-width: 0px;
`;

export default ({ children }) => (
  <TemplateContainer>
    <PageContent>{children}</PageContent>
  </TemplateContainer>
);
