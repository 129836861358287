import React from 'react'
import styled from 'styled-components';
import plebChatGif from "../../images/pleb_chat_1000.gif";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: canter;
  margin-bottom: 24px;
`

const StyledImg = styled.img`
  max-height: 600px; 
`

export default function GifContainer() {
  return <Container><StyledImg src={plebChatGif} /></Container>
}