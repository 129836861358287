import React, { useState } from "react";

// Style
import styled from "styled-components";
import { StyledButton, ButtonText } from "../shared/NPRZButton";
import { neuzeitGroLight, fontSize } from "../../styles/fonts";

// Component
import usePageAnimation from "./index";

const Container = styled.div`
  margin-bottom: 16px;
`;

const Content = styled.div`
  display: flex;
  align-items: flex-end;
`;

const StyledInput = styled.input`
  height: 20px;
  border: none;
  border-bottom: 1px solid black;
  margin: 0px 0px 16px 32px;
  ${neuzeitGroLight}
  font-size: ${fontSize.sm};

  &:focus {
    outline: none;
    border-bottom: 1px solid black;
  }
`;

export default function PageAnimationContainer() {
  const { GradientBlock, setEnter, enter } = usePageAnimation();
  const [title, setTitle] = useState("Goodbye, cruel world");

  function handleClick() {
    setEnter(true);
  }

  function handleType(e) {
    setTitle(e.target.value);
  }

  return (
    <Container>
      <Content>
        <StyledButton onClick={handleClick}>
          <ButtonText>Click Me</ButtonText>
        </StyledButton>
        <StyledInput onChange={handleType} value={title} />
      </Content>
      <GradientBlock enter={enter} setEnter={setEnter} title={title} />
    </Container>
  );
}
