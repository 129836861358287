// style
import styled, { css } from "styled-components";
import { neuzeitGroLight, fontSize } from "../../styles/fonts";

const buttonStyle = css`
  margin: 16px 0px;
  border: 1px solid black;
  padding: 6px 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  background-color: white;
  width: 100px;
  min-width: 0px;
  white-space: nowrap;
  transition: color 0.25s, background-color 0.25s, padding 0.25s;

  &:hover {
    color: white;
    background-color: black;
  }
`;

export const StyledButton = styled.div`
  ${buttonStyle}
`;

export const StyledLabel = styled.label`
  ${buttonStyle}

  ${(props) =>
    props.fileName !== null &&
    css`
      padding: 6px 0px;
    `}
`;

export const ButtonText = styled.div`
  ${neuzeitGroLight}
  font-size: ${fontSize.sm};
  line-height: 20px;
`;
